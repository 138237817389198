import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Job } from '../types/job';
import PublicButton from './public/button';
import { useState } from 'react';
import { paginate } from '../utils/paginate';

const DefaultPageSize = 25;
const DefaultPage = 0;
type Props = {
    readonly rows: readonly Job[];
};

function renderAddress(row: Job) {
    const { city, state, country } = row;
    return [city, state, country].filter(Boolean).join(',');
}
export const ViewAsColumnJob = (props: Props) => {
    const { rows } = props;
    const [page, setPage] = useState(DefaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(DefaultPageSize);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(DefaultPage);
    };

    function handleViewJob(row: Job) {
        window.open(`/jobs/${row.uuid}`, '_blank');
    }
    return (
        <>
            <Grid container mb={'1rem'}>
                {paginate(rows, rowsPerPage, page).map((row, index) => (
                    <Grid item key={index} xs={12} md={4} xl={4} p={'1rem'}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" color="primary" gutterBottom>
                                    {row?.title}
                                </Typography>
                                <Grid display="flex" justifyContent="space-between" alignItems="center" mb={'1rem'}>
                                    <img src="/images/image-placeholder-500x500.jpg" style={{ width: '100px' }} />
                                    <div style={{ width: '70%' }}>
                                        <Typography variant="h6" color="text.primary">
                                            Systra ANZ
                                        </Typography>
                                        <Typography variant={'body2'}>Canberra, ACT, Australia</Typography>
                                    </div>
                                </Grid>

                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="body2" color={'primary'}>
                                        {'ID: '}
                                    </Typography>
                                    <Typography variant="body2" color="#F38F2F">
                                        {row?.id}
                                    </Typography>
                                </Grid>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="body2" color={'primary'}>
                                        {'Location(s): '}
                                    </Typography>
                                    <Typography variant="body2" color="#F38F2F">
                                        {renderAddress(row)}
                                    </Typography>
                                </Grid>
                                <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography variant="body2" color={'primary'}>
                                        {'Aust. Security Clearance: '}
                                    </Typography>
                                    <Typography variant="body2" color="#F38F2F">
                                        {row.securityClearance}
                                    </Typography>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <PublicButton fullWidth onClick={() => handleViewJob(row)}>
                                    View Job
                                </PublicButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <TablePagination
                component="div"
                count={rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};
