import { useParams } from 'react-router-dom';
import { Grid, Typography, CircularProgress, Link as StyledLink, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../services/fetch';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Organisation } from '../../../types/organisation';
import { validateEditorState } from '../../../components/RichTextEditor/utils/validator';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisations } from '../../../redux/actions/organisationsAction';
import { selectOrganisationsData } from '../../../redux/selectors/organisationsSelector';

function PublicOrganization() {
    const { organizationId } = useParams();
    const dispatch = useDispatch();
    const organizations = useSelector(selectOrganisationsData);
    const [pageSize, setPageSize] = useState<number>(100);
    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);
    const JobQuery = useQuery(
        ['job'],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/public/job/organization/${organizationId}`);
            if (response.error) {
                throw new Error(response.error);
            }
            return response.data;
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <Link
                    to={`/jobs/${params.row.uuid}`}
                    state={{
                        from: location.pathname,
                    }}
                >
                    <StyledLink component="span">{params.row.id}</StyledLink>
                </Link>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            renderCell: (params: any) => <Typography>{params.row.title}</Typography>,
        },
        {
            field: 'organization',
            headerName: 'Organisations',
            renderCell: (params: any) => <Typography>{(organizations || [])?.find((item) => item.id === params.row.organization)?.name}</Typography>,
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'state',
            headerName: 'State',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'minSecClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
            renderCell: (params: any) => {
                const securityClearance = params.row.securityClearance || [];
                if (Array.isArray(securityClearance)) {
                    return securityClearance?.map((item: string) => <Typography key={item}>{item}</Typography>);
                } else {
                    return <Typography>{securityClearance}</Typography>;
                }
            },
        },
    ];

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    const OrganizationOverviewQuery = useQuery<Organisation>(
        ['profile-overview', organizationId],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/public/organization/${organizationId}`);

            return {
                ...response.data,
                organizationId,
            };
        },
        {
            enabled: !!organizationId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const OrganizationOverview = OrganizationOverviewQuery.data;

    if (!OrganizationOverview) {
        return (
            <Grid sx={{ flexGrow: 1, pt: 8, pb: 2 }} container spacing={2} direction="row" display="flex" justifyContent="center">
                {OrganizationOverviewQuery.isLoading ? (
                    <CircularProgress />
                ) : (
                    <div>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                            Error loading Organisation Information
                        </Typography>
                    </div>
                )}
            </Grid>
        );
    }

    return (
        <>
            <Grid>
                <Grid item xs={2}>
                    <Typography variant="h5" p={2} color={'primary'}>
                        <Link to="https://aukusjobs.com">
                            <IconButton color="primary" aria-label="back">
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                        Home
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* Availability details */}
                    <Box padding={1}>
                        <Grid container display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'0.5rem'}>
                            <Typography color="primary" variant="h6">
                                {' '}
                                <b>Overview</b>
                            </Typography>
                        </Grid>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid sx={{ flexGrow: 1 }} container spacing={1} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>ID:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', fontWeight: '500' }}>
                                            {OrganizationOverview.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Name:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {OrganizationOverview.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography sx={{ display: 'inline', mr: 1 }}>Location:</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography color={'#F38F2F'} sx={{ display: 'inline', mr: 1, fontWeight: '500' }}>
                                            {OrganizationOverview.address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </Grid>
            </Grid>

            {/* Career Summary & Aspirations */}
            <Box padding={1}>
                <Card variant="outlined">
                    <CardContent>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography color={'primary'} variant="h6" sx={{ fontWeight: '500' }}>
                                    Overview
                                </Typography>
                            </Grid>
                            {!validateEditorState(OrganizationOverview.meta?.overview?.editorState || '') && (
                                <Grid item xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: OrganizationOverview.meta?.overview?.renderedHtml || '' }} />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            {/* job listing */}
            <Grid item xs={12} mt={'2rem'}>
                <DataGrid
                    style={{
                        minHeight: 500,
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    autoHeight
                    rows={JobQuery.data || []}
                    columns={columns}
                    rowHeight={38}
                    disableSelectionOnClick
                />
            </Grid>
        </>
    );
}

export default PublicOrganization;
