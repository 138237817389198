import { Grid, Card, CardContent, Typography, CardActions, Divider, TablePagination } from '@mui/material';
import PublicButton from './public/button';
import { SearchRequest } from '../types/search';
import { Skill } from '../types/skill';
import { skillsAnswersMap } from '../constant';
import { useState } from 'react';
import { paginate } from '../utils/paginate';

type Props = {
    readonly rows: readonly any[];
    searchRequest: SearchRequest;
    skills: Skill[];
};

export const renderAddress = (row: any) => {
    if (row.location) {
        const { city, state, country } = row.location;
        return [city, state, country].filter(Boolean).join(',');
    }
    return '';
};

const DefaultPageSize = 25;
const DefaultPage = 0;

export const ViewAsColumnCandidate = (props: Props) => {
    const { rows, searchRequest, skills } = props;
    const skillsInSearch = searchRequest.skills && Object.keys(searchRequest.skills).length > 0;
    const geoInSearch = searchRequest.geography && Object.keys(searchRequest.geography).length > 0;
    const emptySearch = !skillsInSearch && !geoInSearch;
    const [page, setPage] = useState(DefaultPage);
    const [rowsPerPage, setRowsPerPage] = useState(DefaultPageSize);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(DefaultPage);
    };

    const handleViewProfile = (row: any) => {
        window.open(`/candidates/${row.userId}`, '_blank');
    };

    return (
        <>
            <Grid container mb={'1rem'}>
                {/* filter this row based on pagaination  */}
                {paginate(rows, rowsPerPage, page).map((row, index) => (
                    <Grid item key={index} xs={12} md={4} xl={4} p={'1rem'}>
                        <Card>
                            <CardContent>
                                <Grid mb={'0.5rem'}>
                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Grid display={'flex'} alignItems={'center'}>
                                            <Typography variant="body2" color={'primary'}>
                                                {emptySearch ? 'Last Updated: ' : 'Rank: '}
                                            </Typography>
                                            <Typography variant="body2" color="#F38F2F">
                                                {row?.rank}
                                            </Typography>
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'}>
                                            <Typography variant="body2" color={'primary'}>
                                                {'ID: '}
                                            </Typography>
                                            <Typography variant="body2" color="#F38F2F">
                                                {' '}
                                                {row?.candidateId}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid mb={'0.5rem'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {'Location: '}
                                        </Typography>
                                        <Typography variant="body2" color="#F38F2F">
                                            {renderAddress(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid mb={'0.5rem'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {'Aust. citizenship:'}
                                        </Typography>
                                        <Typography variant="body2" color="#F38F2F">
                                            {row?.auCitizenshipStatus}
                                        </Typography>
                                    </Grid>
                                    <Grid mb={'0.5rem'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography variant="body2" color={'primary'}>
                                            {'Security Clearance: '}
                                        </Typography>
                                        <Typography variant="body2" color="#F38F2F">
                                            {row.securityClearance ? 'True' : 'False'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {skillsInSearch && (
                                    <>
                                        <Divider />
                                        <Grid mt={'0.5rem'}>
                                            {Object.keys(searchRequest?.skills ?? {}).map((key) => {
                                                const skill = skills.find((skill) => skill.id === key);
                                                const title = `${skill?.display}:`;
                                                const result = skillsAnswersMap.get(row[key]) || `N/A`;
                                                // if (!result) {
                                                //     return;
                                                // }
                                                return (
                                                    <Grid display={'flex'} alignItems={'center'} key={key} justifyContent={'space-between'}>
                                                        <Typography variant="body2" color={'primary'}>
                                                            {title}
                                                        </Typography>
                                                        <Typography variant="body2" color="#F38F2F">
                                                            {result}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </>
                                )}
                            </CardContent>
                            <CardActions>
                                <PublicButton fullWidth onClick={() => handleViewProfile(row)}>
                                    View Profile
                                </PublicButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <TablePagination
                component="div"
                count={rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};
