import { Grid, Typography, Divider, Chip } from '@mui/material';
import { validateEditorState } from '../../../../components/RichTextEditor/utils/validator';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../../services/fetch';

export interface PreviewProps {
    form: any;
    organization: any;
}

const Preview = (props: PreviewProps) => {
    const { form, organization } = props;
    const organizationDetail = organization?.org;
    const selectedSkills = form?.skills?.skills;

    const skillIds = Object.keys(selectedSkills);

    const skillQueryInfo = useQuery(
        ['skills', { skillIds }],
        async () => {
            const response = await getInstance().post<{ data: any; error: string }, any>(`/skills/ids`, { skillIds });
            if (response.error) {
                throw new Error(response.error);
            }
            return response.data
                .map((item: any) => {
                    const modifier = selectedSkills[item.id].modifier;
                    if (modifier === 'MANDATORY') {
                        item.sort = 16;
                    } else if (modifier === 'HIGHLY_DESIRABLE') {
                        item.sort = 8;
                    } else if (modifier === 'DESIRABLE') {
                        item.sort = 4;
                    } else {
                        item.sort = 2;
                    }
                    return item;
                })
                .sort((a: any, b: any) => b.sort - a.sort);
        },
        {
            enabled: skillIds.length > 0,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    return (
        <>
            {/* <Grid container mt={'2rem'}>
                <Grid item xs={10} display={'flex'}>
                    <Typography variant="h5" mr={'1rem'}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            onClick={() => {
                                navigate('../jobs');
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        {JobQuery.data?.jobDetail.id}
                    </Typography>
                    <Typography variant="h5" color={'primary'}>
                        {JobQuery.data?.jobDetail.title}, {JobQuery.data?.jobDetail.city}, {JobQuery.data?.jobDetail.state},{' '}
                        {JobQuery.data?.jobDetail.securityClearance}
                    </Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    {!JobQuery.data?.jobDetail.hasApplied && (
                        <Button onClick={handleApply} color="info" variant="contained" sx={{ marginRight: '1rem' }}>
                            Apply Now
                        </Button>
                    )}
                    {JobQuery.data?.jobDetail.hasApplied && (
                        <GrayedButton onClick={handleAlreadyApplied} variant="contained" sx={{ marginRight: '1rem' }}>
                            You have applied
                        </GrayedButton>
                    )}
                </Grid>
            </Grid> */}
            <Divider sx={{ marginTop: '2rem' }} />
            <Grid mt={'2rem'} container>
                {form?.description.city && form?.description.state && form?.description.country && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-location-dot"></i> Company: {organizationDetail.name}
                        </p>
                    </Grid>
                )}
                {form?.description.city && form?.description.state && form?.description.country && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-location-dot"></i> Location:{' '}
                            {`${form?.description.city}, ${form?.description.state}, ${form?.description.country}`}
                        </p>
                    </Grid>
                )}

                {(form?.description.workingArrangement || []).length > 0 && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-briefcase"></i> Working Arrangement:{' '}
                            {form?.description.workingArrangement?.map((item: any) => (
                                <Chip key={item} label={item} sx={{ marginRight: '2px' }} />
                            ))}
                        </p>
                    </Grid>
                )}
                {(form?.description.employmentModal || []).length > 0 && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-briefcase"></i> Employment Model(s):{' '}
                            {form?.description.employmentModal?.map((item: any) => (
                                <Chip key={item} label={item} sx={{ marginRight: '2px' }} />
                            ))}
                        </p>
                    </Grid>
                )}
                {form?.description.salaryRange && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-hand-holding-dollar"></i> Salary: <Chip label={form?.description.salaryRange}></Chip>
                        </p>
                    </Grid>
                )}

                {form?.description.securityClearance && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-shield"></i> Minimum Australian Security Clearance:{' '}
                            <Chip label={form?.description.securityClearance}></Chip>
                        </p>
                    </Grid>
                )}
                {form?.description.startDate && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-shield"></i> StartDate: <Chip label={form?.description.startDate}></Chip>
                        </p>
                    </Grid>
                )}
                {form?.description.endDate && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-shield"></i> End Date: <Chip label={form?.description.endDate}></Chip>
                        </p>
                    </Grid>
                )}
            </Grid>
            <Grid>
                <Typography variant="h6" color={'primary'}>
                    Role Description
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: form?.description.roleDescription?.renderedHtml || '' }} />

                <Typography variant="h6" color={'primary'} mt={'2rem'}>
                    Key Requirements
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: form?.description.keyRequirement?.renderedHtml || '' }} />

                {(skillQueryInfo.data || []).length > 0 && (
                    <>
                        <Typography variant="h6" color={'primary'} mt={'2rem'}>
                            Desired Skills and Experience
                        </Typography>
                        <Typography>
                            Candidates should ensure their profiles include accurate and justifiable statements of competency and experience (if any) in the
                            following categories
                        </Typography>
                        <Grid container>
                            <ul>
                                {skillQueryInfo.data.map((item: any) => (
                                    <li key={item.display}>{item.display}</li>
                                ))}
                            </ul>
                        </Grid>
                    </>
                )}

                {!validateEditorState(form?.description.additionalDescriptionNotes?.editorState || '') && (
                    <>
                        <Typography variant="h6" color={'primary'} mt={'2rem'}>
                            Additional Information
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: form?.description.additionalDescriptionNotes?.renderedHtml || '' }} />
                    </>
                )}
            </Grid>
            <Divider />
            <Grid>
                {!validateEditorState(organizationDetail.meta?.overview?.editorState || '') && (
                    <>
                        <Typography variant="h6" color={'primary'} mt={'2rem'}>
                            About {organizationDetail?.name}
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: organizationDetail.meta?.overview?.renderedHtml || '' }} />
                    </>
                )}
            </Grid>
            {/* move this back button to right  */}
            <Divider />
            {/* <Grid display={'flex'} sx={{ marginTop: '1rem' }}>
                <Button
                    variant="contained"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                        navigate('../jobs');
                    }}
                >
                    Back
                </Button>
            </Grid> */}
        </>
    );
};

export default Preview;
