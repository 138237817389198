import { Box, Button, Grid, Link, Typography } from '@mui/material';

const JobClosed = () => {
    //
    return (
        <Box>
            <Typography variant="h5" color="primary" textAlign={'center'} className="mt-3">
                Bummer, this job is no longer available
            </Typography>

            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} className="mt-3">
                <img className="job-close-main-img mb-5" src={'/images/drydock.jpg'} />
                <Link href={'/jobs'}>
                    <Button variant="contained" color="info">
                        Return To Job Boards
                    </Button>
                </Link>
            </Grid>
        </Box>
    );
};

export default JobClosed;
